.select {

	&__control {
		height: 44px;
		background: #F4F6FB !important;
		border: 1px solid rgba(156, 163, 176, 0.1) !important;

		&-is-focused {
			border: 1px solid #8A92A1 !important;
		}
	}

	&__placeholder {
		color: #283445 !important;
		font-size: 16px !important;
		margin: 0 !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		white-space: nowrap !important;
	}

	&__value {
		&-container {
			overflow-x: auto !important;
			padding: 2px 16px !important;
			flex-wrap: nowrap !important;
		}
	}

	&__multi {
		&-value {
			min-width: 80px;
			background: #dee6f5 !important;

			&__remove {
				svg {
					fill: #283445 !important;
				}
			}
		}
	}

	&--is-disabled {
		opacity: .7;
		cursor: default;
	}

	&__indicator {
		color: #8A92A1 !important;

		&-separator {
			display: none;
		}
	}
}
