.CarLotComment {
	margin-top: 16px;
	background-color: #ffffff;
	border: 1px solid #dee6f5;
	border-radius: 8px;
	padding: 12px;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;
	}

	.title {
		margin-bottom: 15px;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		color: #283445;

		span {
			font-size: 14px;
			color: #8a92a1;
			margin-left: 12px;
			font-weight: normal;
		}
	}

	.comment {
		position: relative;
	}

	.icon {
		position: absolute;
		right: 16px;
		top: 8px;
		color: #6AC96E
	}

	.textarea {
		height: 66px;
		margin-bottom: 8px;

		textarea {
			border: 1px solid #dee6f5;
			resize: none;
		}
	}

	@media (max-width: 450px) {
		.title {
			display: flex;
			flex-direction: column;

			span {
				margin-left: 0;
			}
		}
	}
}
