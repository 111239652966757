.Filter {
	margin: 25px 0 15px 10px;
	border-bottom: 1px solid #DEE6F5;

	&.indent {
		margin-left: 0;
	}

	&.open {

	}

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 32px;
	}

	.close {
		display: none;
	}

	.title {
		cursor: pointer;
		font-weight: bold;
		font-size: 22px;
		line-height: 120%;
		color: #283445;
		display: inline-flex;
		align-items: center;

		span {
			display: inline-block;
			color: #fff;
			padding: 2px 4px;
			background: #3c83ed;
			border-radius: 8px;
			font-size: 12px;
			translate: 0 -8px;
			line-height: 1
		}

		svg {
			rotate: 180deg;
			transition: .3s ease-in-out;
			margin-left: 8px;
		}
	}

	&.open .title svg {
		rotate: 0deg;
	}

	.titleSmall {
		font-weight: bold;
		font-size: 16px;
		line-height: 120%;
		color: #283445;
		display: inline-flex;
		align-items: center;
		margin-bottom: 24px;
		padding-top: 8px;
		cursor: pointer;

		svg {
			rotate: 180deg;
			transition: .3s ease-in-out;
			margin-left: 8px;
		}
	}

	.openAdd {
		svg {
			rotate: 0deg
		}
	}

	.section {
		padding: 20px;
		margin-top: 15px;
		background: white;
		border: 1px solid #DEE6F5;
		box-sizing: border-box;
		border-radius: 8px;
	}

	.main {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-bottom: 24px;
	}

	.additional {

	}

	.item {
		width: 268px;
		flex-shrink: 0;
	}

	.name {
		font-size: 12px;
		margin-bottom: 4px;
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.red {
		color: #E92D2D;
	}

	.disabled {
		opacity: .3;
	}

	.hidden {
		display: none;
	}

	.footer {
		display: flex;
		grid-gap: 8px;
	}

	.reset {
		div {
			display: none;
		}
	}

	@media (max-width: 1100px) {
		.main {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.item {
			width: 100%;
		}
	}

	@media (max-width: 850px) {
		.main {
			display: grid;
			grid-template-columns: 1fr;
		}

		.item {
			width: 100%;
		}
	}

	@media (max-width: 780px) {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		z-index: 1000;
		background: #F4F6FB;
		margin: 0;
		padding: 0 16px;
		overscroll-behavior: contain;
		translate: 0 -100%;
		transition: .3s ease-in-out;

		&.show {
			translate: 0 0;
		}

		.header {
			margin-bottom: 0;
			justify-content: space-between;
			position: sticky;
			top: 0;
			left: 0;
			background: #F4F6FB;
			z-index: 10;
			padding: 8px 0;
		}

		.title {

			svg {
				display: none;
			}
		}

		.close {
			color: #8A92A1;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.section {
			border: none;
			margin-top: 0;
		}

		.scroll {

		}

		.main {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.item {
			width: 100%;
		}

		.reset {
			height: auto !important;
			width: auto !important;
			z-index: 12;
			display: inline-flex;
			border: none !important;
			background: none !important;

			span {
				display: none;
			}

			div {
				display: flex;
				align-items: center;
				color: #E92D2D;

				svg {
					margin-right: 8px;
				}
			}
		}

		.footer {
			border-top: 1px solid #DEE6F5;
			box-shadow: 0 -4px 12px 0 rgba(40, 52, 69, 0.08);
			position: sticky;
			left: 0;
			bottom: 0;
			padding: 8px 32px;
			background: #fff;
			margin: 0 -32px;
			z-index: 12;

			button {
				width: 100%;
			}
		}
	}

	@media (max-width: 500px) {
		.main {
			display: grid;
			grid-template-columns: 1fr;
		}

		.item {
			width: 100%;
		}
	}
}
