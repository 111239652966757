.auction-lots {
	.text-info {
		display: flex;
		align-items: center;
		gap: 6px;
		padding: 8px;
		background-color: #dee6f5;
		border: 1px solid #dee6f5;
		border-radius: 6px;
		color: #2d78e9 !important;
		font-size: 12px;
		font-weight: 500;
	}

	.lots-form-wrapper {
		border-bottom: 1px solid #dee6f5;
		border-top: 1px solid #dee6f5;
		padding: 16px 0;
	}

	.datetime-local,
	.input-lot-form {
		max-width: 320px;
	}

	.input-name {
		position: absolute;
		margin-top: -25px;
		color: #656c7b;
		font-size: 12px;
		font-weight: 500;
	}

	&__input-commission {
		width: 80px;
		text-align: right;
		padding-right: 32px;

		&::placeholder {
			color: #283445;
		}
	}

	&__input-minimal-commission {
		width: 140px;
		text-align: right;
		padding-right: 32px;

		&::placeholder {
			color: #283445;
		}
	}

	&__placeholder-symbol {
		font-size: 16px;
		position: absolute;
		top: 10px;
		right: 12px;
		color: #656c7b;
		font-weight: 500;
	}

	.btn {
		padding: 6px 12px !important;
	}

	.lot-form-checked {
		border: 2px solid #8a92a2;
		border-radius: 4px;
	}

	.btn-add-car {
		display: flex;
		align-items: center;
		gap: 2px;
		padding: 6px 8px;
		background: #f4f6fb;
		border-radius: 6px;
		color: #2d78e9;
		border: 1px solid #f4f6fb;
		font-weight: 700;
		font-size: 14px;
		line-height: 140%;
	}

	.lot-wrapper-card {
		position: relative;
		background: #ffffff;
		border: 1px solid #dee6f5;
		box-sizing: border-box;
		border-radius: 8px;

		.info-wrapper__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 6px 12px;
		}

		.info-wrapper__header-edit {
			display: flex;
			gap: 16px;
			align-items: center;
		}

		.info-wrapper__header-edit-btn {
			display: flex;
			align-items: center;
			padding: 6px 8px;
			gap: 2px;
			background: #fafbff;
			border-radius: 6px;
			border: 1px solid #fafbff;
			color: #2d78e9;
			font-weight: 700;
		}

		.lot-wrapper-card__body {
			padding: 16px;
		}

		.head-name {
			font-weight: 600;
			font-size: 12px;
			color: #656c7b;
		}

		.row-text {
			font-weight: 500;
			font-size: 16px;
			color: #283445;
		}
	}

	.report-info {
		padding: 0 6px 16px 10px;
	}

	.add-report {
		color: red;
		border: none;
		padding: 0;
		background: none;
		font-size: 12px;

		span {
			border-bottom: 1px dashed;
		}
	}
}
