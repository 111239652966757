.noRoles {
	background: #EDF1FA;
	height: 182px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 8px;
	color: #8A92A1;

	.title {
		font-size: 22px;
		line-height: 30px;
	}

	.text {
		font-size: 14px;
		line-height: 18px;
	}
}
