.Badge {
	border-radius: 6px;
	padding: 0 8px;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 12px;
	line-height: 1;
	height: 28px;

	&.gray {
		background: #edf1fa;
		color: #656c7b;
	}
}
