.UploadField {
	display: flex;
	align-items: center;
	margin-top: 8px;

	.name {
		width: 30%;
		font-weight: bold;
		font-size: 16px;
		line-height: 140%;
		color: #283445;

		&.required {
			color: #ff0000;
		}
	}

	.field {
		width: 70%;
		max-width: 540px;

		&.fieldFull {
			max-width: 100%;
		}
	}

	@media (max-width: 780px) {
		display: block;

		.name {
			margin-bottom: 8px;
		}

		.name,
		.field {
			width: 100%;
			max-width: 100%;
		}
	}
}
