.table {
	border-radius: 8px;
	border-top: 1px solid var(--Light-Border, #DEE6F5);
	border-left: 1px solid var(--Light-Border, #DEE6F5);
	border-right: 1px solid var(--Light-Border, #DEE6F5);
	background: var(--grey04, #EDF1FA);
	overflow: hidden;

	table {
		width: 100%;
		font-size: 14px;
		border-radius: 8px;

		thead tr td,
		tbody tr td {
			vertical-align: middle;

			b {
				font-weight: 700;
			}

			&:first-child {
				padding: 0;
				width: 32px;
				text-align: center;
			}
		}

		thead {

			tr {
				td {
					padding: 8px 16px;
				}
			}
		}

		tbody {
			background: #fff;

			tr {
				td {
					border-top: 1px solid var(--Light-Border, #DEE6F5);
					padding: 16px;
				}
			}
		}
	}
}
