.item {
	display: flex;
	margin-bottom: 24px;

	&:last-child {
		margin-bottom: 0;
	}
}

.title {
	flex-shrink: 0;
	width: 268px;
	margin-right: 16px;
	font-weight: 700;
	font-size: 14px;
}

.section {
	display: flex;
	grid-gap: 8px;
	flex-wrap: wrap;
	justify-content: flex-start;
}

@media screen and (max-width: 560px) {
	.item {
		margin-bottom: 16px;
		flex-direction: column;
	}

	.title {
		margin-bottom: 8px;
	}
}
