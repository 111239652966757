.-mt-4 {
	margin-top: -16px;
}

.mt-30px {
	margin-top: 30px;
}

.mt-25px {
	margin-top: 25px;
}

.mt-20px {
	margin-top: 20px;
}

.mt-12px {
	margin-top: 12px;
}

.mt-15px {
	margin-top: 15px;
}

.mt-16px {
	margin-top: 16px;
}

.mt-10px {
	margin-top: 10px;
}

.mb-10px {
	margin-bottom: 10px;
}

.mb-15px {
	margin-bottom: 15px;
}

.mb-20px {
	margin-bottom: 20px;
}

.mb-24px {
	margin-bottom: 24px;
}

.mb-25px {
	margin-bottom: 25px;
}

.mb-30px {
	margin-bottom: 30px;
}

.ml-5px {
	margin-left: 5px;
}

.ml-15px {
	margin-left: 15px;
}

.mr-3px {
	margin-right: 3px;
}

.mr-20px {
	margin-right: 20px;
}

.mr-30px {
	margin-right: 30px;
}

.gap-2px {
	gap: 2px;
}

.gap-5px {
	gap: 5px;
}

.gap-10px {
	gap: 10px;
}

.gap-15px {
	gap: 15px;
}

.gap-16px {
	gap: 16px;
}

.gap-20px {
	gap: 20px;
}

.gap-24px {
	gap: 24px;
}

.gap-30px {
	gap: 30px;
}

.gap-50px {
	gap: 50px;
}

.gap-100px {
	gap: 100px;
}

.text-secondary {
	color: #9ca3b0 !important;
}

.text-align-center {
	text-align: center;
}

.cursor-pointer {
	cursor: pointer;
}

.w-70 {
	width: 70% !important;
}

.w-30 {
	width: 30% !important;
}

.w-32 {
	width: 32% !important;
}

.w-70px {
	width: 70px;
	flex-shrink: 0;
}

.grid-columns-30-70 {
	grid-template-columns: 30% 70%;
}

.grid-columns-40-60 {
	grid-template-columns: 40% 60%;
}

.link {
	cursor: pointer;
	color: #0d6efd;
}

.break-word {
	word-break: break-word;
}

.btn-large {
	padding: 11px 24px;
}

.btn-ghost {
	border: 2px solid #dee6f5;
	border-radius: 8px;
	box-sizing: border-box;
}

.rotate-180 {
	transform: rotate(180deg);
}

.tabs-wrapper {
	padding: 20px 0;
}

.tabs {
	gap: 10px;
	flex-wrap: wrap;
	margin-top: 20px;

	.tab-btn {
		outline: none;
		padding: 10px 30px;
		border-radius: 60px;
		font-weight: bold;
		font-size: 14px;
		line-height: 150%;
		text-align: center;
		font-feature-settings: "pnum" on, "lnum" on;
		color: #283445;
		background: white;
		border: 1px solid #dee6f5;
	}

	.tab-btn-active {
		border: none;
		background: #283445;
		color: white;
	}
}

.fs-12px {
	font-size: 12px;
}

.h-44px {
	height: 44px;
}

.h-40px {
	height: 40px;
}

.mh-60px {
	min-height: 60px;
}

.lot-status {
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 6px 10px;
	border-radius: 6px;
	font-weight: 700;
	font-size: 12px;
	border: none;

	svg {
		border-left: 1px solid #2834451f;
		padding-left: 6px;
		width: 12px;
		height: 12px;
		box-sizing: content-box;
	}
}

.lot-status.green {
	background: rgba(106, 201, 110, 0.1);
	color: #6ac96e;

	svg {
		fill: #339337;
	}
}

.lot-status.grey {
	background-color: #edf1fa;
	color: #656c7b;

	svg {
		fill: #656c7b;
	}
}

.lot-status.red {
	background: rgba(240, 68, 97, 0.1);
	color: #f04461;

	svg {
		fill: #f04461;
	}
}

.lot-status.orange {
	background: rgba(254, 179, 92, 0.1);
	color: #feb35c;

	svg {
		fill: #feb35c;
	}
}

.cursor-default {
	cursor: default !important;
}

.filters-count {
	font-size: 10px;
	font-weight: 700;
	color: #fff;
	background-color: #3c83ed;
	border-radius: 50px;
	height: 18px;
	width: 18px;
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	top: -2px;
	left: 98px;
}

.popover {
	max-width: 320px;
}

.line-clamp-2 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.error {
	color: #F04461;
	font-weight: bold;
	margin-left: 8px;
	font-size: 12px;
	align-items: center;
	display: flex;
}

.input-disabled {
	pointer-events: none;
}

@media screen and (max-width: 780px) {
	.filters-count {
		top: -6px;
		left: 100px;
	}
}
