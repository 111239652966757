.FieldCarForm {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&.indent {
		margin-bottom: 20px;
	}

	.name {
		color: #283445;
		font-size: 16px;
		font-weight: 700;
		line-height: 1;

		span {
			color: red;
			margin-left: 8px;
		}
	}

	.section {
		max-width: 540px;
		width: 100%;
	}

	@media (max-width: 780px) {
		flex-direction: column;
		align-items: flex-start;

		&.indent {
			margin-bottom: 24px;
		}

		.name {
			margin-bottom: 16px;
		}

		.section {
			max-width: 100%;
		}
	}
}
