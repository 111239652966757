.Comment {
	display: grid;
	grid-template-columns: 30% 70%;
	padding: 15px 30px;
	background: #FFFFFF;
	border: 1px solid var(--main-color-light-blue);
	border-radius: 8px;

	.label {
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		color: #283445;
	}

	.section {

	}

	.icon {
		position: absolute;
		right: 16px;
		top: 8px;
		color: #6AC96E
	}

	.block {
		margin-bottom: 20px;
		position: relative;
	}

	.textarea {
		height: 66px;

		textarea {
			height: 66px;
		}
	}

	@media (max-width: 780px) {
		grid-template-columns: 45% 55%;
		padding: 15px;
	}

	@media (max-width: 560px) {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
