.add-user-group-wrapper {
	padding: 20px 30px 0 29px;

	.input {
		max-width: 540px;
	}

	.react-select {
		max-width: 540px;
	}

	.title {
		font-weight: 700;
		font-size: 22px;
		line-height: 135%;
		font-feature-settings:
			"pnum" on,
			"lnum" on;
		color: #283445;
	}

	.name {
		font-weight: bold;
		font-size: 16px;
		line-height: 140%;
		font-feature-settings:
			"pnum" on,
			"lnum" on;
		color: #283445;
	}

	.info-wrapper {
		padding: 30px;
		background: #ffffff;
		border: 1px solid #dee6f5;
		box-sizing: border-box;
		border-radius: 8px;
	}

	.tooltip-wrapper {
		width: 24px;
		height: 24px;
		margin-left: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f2f6ff;
		border: 1px solid #dee6f5;
		border-radius: 50%;
	}

	@media (max-width: 600px) {
		.filter {
			flex-direction: column;
			gap: 8px !important;
			align-items: flex-start !important;

			.w-50,
			.w-70 {
				width: 100% !important;
			}
		}
	}
}
