.suggestions {
	&.xs {
		width: 265px;
	}

	&__list-wrap {
		position: relative;
	}

	&__list {
		box-sizing: border-box;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		max-height: 400px;
		z-index: 10;
		background: #fff;
		border: 1px solid #f4f6fb;
		box-shadow: 0 24px 24px rgba(0, 0, 0, 0.25);
		border-radius: 6px;
		overflow-y: auto;
		color: #000;
	}

	&__list-item {
		cursor: pointer;
		padding: 7px 12px;
	}

	&__list-item_hover {
		background: #f4f6fb;
	}
}
