.BreadCrumbs {
	color: var(--grey00, #656C7B);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 12px;
	display: flex;
	align-items: center;

	a {
		color: var(--grey00, #656C7B);
	}
}
