.UploadedFile {
	display: flex;
	align-items: center;

	.image {
		display: block;
		margin-right: 16px;

		img {
			width: 48px;
		}
	}

	.desc {
		display: block;
		margin-right: 16px;
	}

	.name {
	}

	.size {
		margin-right: 10px;
		font-weight: 500;
		font-size: 12px;
		color: #9ca3b0;
	}

	.delete {
		cursor: pointer;
	}
}
