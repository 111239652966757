.Btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	line-height: 1;
	cursor: pointer;
	transition: .3s ease-in-out;
	border-radius: 8px;
	position: relative;
	border: none;

	&.normal {
		padding: 0 24px;
		height: 44px;
	}

	&.small {
		padding: 0 16px;
		height: 32px;
	}

	&.blue {
		color: #fff;
		background: #2D78E9;

		&:hover {
			background: #0a58ca;
		}
	}

	&.white {
		background: #fff;
		border: 2px solid #DEE6F5
	}

	&.redBorder {
		background: #fff;
		border: 2px solid #F04461;
		color: #F04461
	}

	svg {
		margin: 0 8px 0 -4px;
	}

	a {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&.fontSmall {
		font-size: 14px;
	}

	&.fontNormal {
		font-size: 16px;
	}

	@media screen and (max-width: 560px) {
		&.normal {
			padding: 0 12px
		}

		&.small {
			padding: 0 8px;
		}
	}
}
