$custom-grey: #283445;
$pnum-lnum:
	"pnum" on,
	"lnum" on;

@mixin flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.save-btn,
.set-to,
.comment,
.table-wrapper thead th {
	font-size: 14px;
	font-feature-settings: $pnum-lnum;
	color: $custom-grey;
}

.length,
.confirm-btn,
.diagnostic-wrapper .percent,
.comments-wrapper .comments-length {
	font-feature-settings: $pnum-lnum;
	color: $custom-grey;
}

.label,
.form-wrapper,
.progress_custom {
	font-size: 14px;
}

.form-wrapper .plus {
	font-feature-settings: $pnum-lnum;
}

.open-accordion {
	background: $custom-grey;
}

.state-accordion-wrapper {
	.additional-wrapper {
		.name,
		.status {
			font-feature-settings: $pnum-lnum;
			font-size: 14px;
			line-height: 150%;
			font-weight: 500;
		}

		.name {
			color: $custom-grey;
		}

		.status {
			color: #6ac96e;
		}
	}
}

.form {
	@include flex-wrap;
	border-radius: 0.5em;
	height: 480px;
	overflow-y: scroll;
	align-content: baseline;
}

.report-img {
	border-radius: 1rem;
	height: 150px;
	object-fit: cover;
}

.confirm-btn {
	padding: 5px 10px;
	border: 1px solid #f4f6fb;
	border-radius: 5px;
	background: white;
	font-weight: 700;
	font-size: 12px;
	line-height: 150%;
	text-align: center;
}

.progress_custom {
	// здесь перезаписываются стили бутстрапа
	height: 2em;
	background: var(--main-color-light-blue);
	font-weight: 700;
	border-radius: 0.25rem;
	overflow: hidden;
}

.diagnostic-wrapper {
	.progress {
		height: 30px;
	}

	.accordion-head {
		width: 260px;
	}

	.accordion-button {
		background: #f4f6fb;
	}

	.percent {
		font-weight: 700;
		font-size: 12px;
		line-height: 150%;
	}
}

.length {
	font-weight: 500;
	font-size: 12px;
	line-height: 150%;
	text-align: right;
}

.open-accordion {
	display: flex;
	height: 24px;
	width: 24px;
	color: #fff;
	font-weight: 700;

	div .svg-icon {
		transform: scale(0.5);
	}
}

.save-btn {
	border: 2px solid var(--main-color-light-blue);
	margin-left: 10px;
	background: white;
	width: 180px;
	padding: 11px;
	border-radius: 8px;
	font-weight: bold;
	line-height: 150%;
	text-align: center;
	outline: none;
}

.set-to {
	border: 1px solid #f4f6fb;
	margin-left: 10px;
	background: white;
	width: 100%;
	max-width: 253px;
	padding: 11px;
	border-radius: 8px;
	font-weight: bold;
	line-height: 150%;
	text-align: center;
	outline: none;
}

.buttons-wrapper {
	width: 40%;
}

.btn-close {
	position: relative;
	left: 2em;
	bottom: 1em;
}

.table-wrapper {
	padding: 15px 60px 0 24px;
	background: #f4f6fb;
	border: 1px solid var(--main-color-light-blue);
	border-radius: 8px;

	.table > :not(caption) > * > * {
		border-bottom-width: 0;
	}

	thead {
		background: #e9effa;
		border: 1px solid var(--main-color-light-blue);
		border-radius: 8px 0 0 8px;

		th {
			border-right: 1px solid var(--main-color-light-blue);
			font-weight: 700;
			line-height: 150%;
		}
	}

	.head-tr td,
	.bg-altered {
		background-color: #f6f8fe;
	}

	.close-btn {
		top: 0;
		right: 0;
		width: 24px;
		display: flex;
		justify-content: center;
		background: var(--main-color-light-blue);
		border-radius: 0px 8px;
		color: #3c83ed;
		font-weight: bold;
		cursor: pointer;
	}

	.add-photo-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.5em;
		height: 1.5em;
		font-size: 2em;
		background: #3c83ed;
		border-radius: 8px;
		padding: 10px;
		color: #fff;
		font-weight: 700;
		border: none;
		outline: none;

		.svg-icon {
			transform: scale(0.8);
		}
	}

	.description {
		width: 340px;
	}

	input[type="radio"] {
		margin-right: 20px;
	}
}

.accordion-body {
	.actions {
		display: flex;
		justify-content: flex-end;
	}
}

.comments-wrapper {
	.title {
		font-size: 16px;
	}

	.comment-field {
		width: 100%;
		background: #ffffff;
		border: 1px solid var(--main-color-light-blue);
		border-radius: 6px;
	}

	.comments-length {
		font-weight: 700;
		font-size: 16px;
		line-height: 140%;
	}
}

.modal-header {
	padding: 0 !important; //здесь перезаписывается стиль бутстрапа, накладываемый позднее, соотв нужен импортант
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modal-content {
	padding: 25px 35px 20px 35px;
	background-color: #fff !important;
}

.modal-footer {
	padding: 0.75rem 0 0;
}

.accordion-tooltip {
	right: -10%;

	&__draggable {
		right: -10%;
		transform: translateY(-50%);
	}
}

.tooltip-wrapper {
	width: 24px;
	height: 24px;
	margin-left: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f2f6ff;
	border: 1px solid var(--main-color-light-blue);
	border-radius: 50%;
}

.image-wrapper {
	width: 540px;
	padding: 10px 23px;
	background: #f4f6fb;
	border: 1px solid rgba(156, 163, 176, 0.1);
	border-radius: 6px;

	div {
		width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.selected-images {
	display: flex;
	flex-direction: column;
	align-items: end;
}

.form-wrapper {
	width: 540px;

	.label {
		font-weight: 600;
		line-height: 17px;
		color: #343f44;
	}

	.form-control {
		padding: 15px 16px;
		background: #ffffff;
		border: 1px solid #aeb2b4;
		box-sizing: border-box;
		border-radius: 10px;
	}

	.input-wrapper {
		opacity: 0;
		position: absolute;
		top: 0;
	}

	.form-control[type="file"] {
		height: 150px;
	}

	.file-wrapper {
		box-sizing: border-box;
		border-radius: 10px;

		.dashed-border {
			width: 100%;
			cursor: pointer;
			background: #ffffff;
			border: 1px dashed #cad1dc;
			box-sizing: border-box;
			justify-content: center;

			.text {
				text-align: center;
				margin-bottom: 6px;
				font-weight: 400;
				font-size: 13px;
				line-height: 17px;
				color: #515e72;
			}

			.upload-btn {
				background: #ffffff;
				border: 1px solid #cad1dc;
				box-sizing: border-box;
				border-radius: 3px;
				padding: 7px;
			}
		}

		.form-control {
			margin: 12px 20px;
			border: 1px dashed #cad1dc;
		}
	}

	.plus {
		font-family: "Raleway";
		font-weight: 300;
		font-size: 64px;
		line-height: 75px;
		text-align: center;
		color: #9ca3b0;
	}
}

.company-name {
	margin-left: 12px;

	.name {
		font-weight: 700;
		font-size: 13px;
		line-height: 150%;
		color: #11142d;
	}

	.company {
		font-weight: 400;
		font-size: 12px;
		line-height: 120%;
		letter-spacing: 0.008em;
		color: #9ca3b0;
	}
}

.comment {
	font-weight: 500;
	line-height: 150%;
}

@media screen and (max-width: 780px) {
	@mixin column-100 {
		width: 100%;
		flex-direction: column;
	}

	@mixin padding-wrap {
		@include flex-wrap;
		padding: 4vw !important;
	}

	.reports-wrapper {
		padding: 4vw !important;
	}

	.accordion {
		&-head {
			flex-direction: column;
			align-items: baseline;
		}

		&-body {
			padding: 10px !important;

			.row {
				@include padding-wrap;
				gap: 1vw;
				margin: 1vw;
				border: 1px solid var(--main-color-light-blue);
				border-radius: 10px;

				.col-6 {
					// здесь нужна полноразмерная строка
					width: 100% !important;
					padding: 0 !important;

					.col-name {
						gap: 4vw;
						align-items: start;

						div {
							font-weight: 700;
						}

						input {
							margin-right: 0 !important;
						}
					}
				}

				.accordion-defects-group,
				.text-end {
					padding: 0 !important;
				}

				.accordion-defects-group {
					width: 75% !important;

					span:not(.green-text) {
						color: #f04461;
					}

					.defect-short span {
						color: var(--bs-body-color);
					}
				}

				.text-end {
					width: 25% !important;
				}
			}

			.comments-wrapper {
				.mob-comments-flex {
					flex-direction: column;
					align-items: start !important;
					gap: 4vw;

					.title {
						font-size: 18px;
						margin-left: 10px;
					}
				}
			}
		}

		&-button {
			padding: 2vw;
		}

		.progress_custom {
			width: 100% !important;
		}

		.accordion-header {
			button {
				padding: 3vw;
			}
		}
	}

	.table-wrapper {
		padding: 10px !important;

		select,
		input:not([type="checkbox"]) {
			width: 100%;
			margin: 0 0 2vw;
		}

		.mob-tab-header {
			@include column-100;
			padding: 2vw;
			gap: 4vw;
			margin-top: 8vw;

			.d-flex {
				justify-content: space-between;
				@include column-100;

				&:first-child {
					flex-direction: row;
				}

				input:not([type="checkbox"]),
				select {
					margin: auto;
				}
			}
		}

		.close-btn {
			flex-direction: column;
			@include flex-wrap;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			width: 36px;
			height: 36px;
			line-height: 100%;
		}
	}

	.mob-border-bottom {
		padding-bottom: 2vw;
		border-bottom: 1px solid var(--main-color-light-blue);
	}

	.mob-bold {
		font-weight: 600;
	}

	.mob-wheel {
		&__wrapper,
		&__button {
			display: flex;
		}

		&__gap {
			gap: 2vw 0;
		}

		&__wrapper {
			@include padding-wrap;
			background: var(--bs-body-bg);
			border: 1px solid var(--main-color-light-blue);
			border-radius: 10px;

			margin: 2vw 0;
		}

		&__photos {
			width: 100%;

			.d-flex {
				flex-direction: column;

				.accordion-images-preview {
					flex-direction: inherit;
					margin-bottom: 4vw;

					.wheel-image {
						width: 100%;
						object-fit: contain;
						border-radius: 2vw;
					}
				}
			}
		}

		&__button {
			flex-direction: row;
			align-items: center;
			background: var(--bs-body-bg);
			border: 2px solid var(--main-color-light-blue);
			border-radius: 2vw;

			.add-photo-btn {
				transform: scale(0.8);
			}

			.mob-btn {
				&__caption {
					width: 80%;
				}

				&__icon {
					width: 48px;
				}
			}
		}
	}
}
