.user-profile {
	padding: 20px 30px;

	.title {
		font-weight: 700;
		font-size: 22px;
		line-height: 135%;
		color: #283445;
	}

	.user-info {
		padding: 15px 30px;
		background: #ffffff;
		border: 1px solid #dee6f5;
		box-sizing: border-box;
		border-radius: 8px;

		&__field-wrapper {
			display: grid;
			grid-template-columns: 30% 70%;
			border-bottom: 1px solid #dee6f5;
			padding: 10px 0;
			grid-gap: 10px;
		}

		&__field-wrapper:last-child {
			border-bottom: none;
		}
	}

	.line {
		margin-top: 9px !important;
		border: none;
		background: #dee6f5;
		height: 1px;
	}

	.name {
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		color: #283445;
	}

	.value {
		text-align: left;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: #283445;

		&_responsible {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	.actions {
		.block-btn {
			border: 1px solid #c3c3c7;
			margin-left: 10px;
			background: #f4f6fb;
			width: 100%;
			max-width: 202px;
			padding: 11px 28px;
			margin-right: 15px;
			border-radius: 8px;
			font-weight: bold;
			font-size: 14px;
			text-align: center;
			outline: none;
		}
	}

	&__item-checkboxs {
		height: 40px;
		display: flex;
		align-items: center;
	}

	&__item-checkbox {
		opacity: 0.7;
		margin-right: 50px;
		display: flex;
		align-items: center;
		line-height: 18px;

		.form-check-label {
			line-height: 18px;
		}
	}
}

@media screen and (max-width: 780px) {
	.user-profile {
		padding: 20px;

		.user-info {
			padding: 15px;
		}

		.user-info__field-wrapper {
			word-break: break-word;
			grid-template-columns: 45% 55%;
		}

		&__item-checkboxs {
			flex-direction: column;
			align-items: start;
			gap: 4px;
		}
	}
}

@media screen and (min-width: 780px) and (max-width: 1200px) {
	.user-profile {
		.user-info__field-wrapper,
		.info-list__item {
			word-break: break-word;
			grid-template-columns: 45% 55%;
			gap: 20px;
		}
	}
}
