.Permissions {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #DEE6F5;
	padding: 20px 0;

	&.hasInput {
		padding: 0;
		margin-bottom: 20px;
		border: none;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}

	.col {
		&:first-child {
			width: 200px;
			margin-right: 20px;
		}

		&:nth-child(2) {
			width: 500px;
			margin-right: 20px;
		}
	}

	.title {
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		color: var(--dark, #283445);
	}

	.checkbox {
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
