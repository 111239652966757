.users-group-modal-wrapper {
	.modal-content {
		border: 1px solid var(--main-color-light-blue);
	}

	.filters-wrapper {
		margin: 25px 0 15px 10px;

		.filter {
			padding: 20px;
			margin-top: 15px;
			background: white;
			border: 1px solid #dee6f5;
			box-sizing: border-box;
			border-radius: 8px;
		}

		.input {
			max-width: 265px;
		}
	}

	@media (max-width: 500px) {
		.buttons {
			flex-direction: column;
			gap: 8px;
			align-items: flex-start !important;
		}
	}
}
