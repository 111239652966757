.TextArea {
	height: 128px;

	textarea {
		width: 100%;
		height: 100%;
		display: block;
		padding: 8px 16px;
		color: #283445;
		font-size: 16px;
		line-height: 18px;
		background: #f4f6fb;
		border: 1px solid #8a92a1;
		box-sizing: border-box;
		border-radius: 6px;
		outline: none;
		font-weight: 500;
	}
}
