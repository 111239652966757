.tires-wrapper {
	padding: 12px 16px;
	background: #ffffff;
	border: 1px solid #dee6f5;
	box-sizing: border-box;
	border-radius: 8px;

	.title-season {
		font-weight: 700;
		font-size: 14px;
		color: #656C7B;
	}

	&__group {
		&-set {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(160px, 200px));
			gap: 6px;
			margin-top: 12px;

			.tire {
				display: flex;
				flex-direction: column;

				.tire-side {
					width: 100%;
					text-align: center;
					font-weight: 700;
					font-size: 14px;
					line-height: 150%;
				}

				.tire-dimensions {
					width: 100%;
					text-align: center;
					font-weight: 500;
					font-size: 12px;
					color: #656C7B;
				}

				.tire-test {
					width: 100%;
					text-align: center;
					color: #283445;
					font-weight: 400;
					font-size: 14px;
					line-height: 150%;
				}

				.tire-photo {
					border-radius: 5px;
					aspect-ratio: 1/1;
					width: 100%;
					object-fit: cover;
				}

				.swiper {
					width: 300px;
				}

				.swiper-wrapper {
					align-items: start;
					max-height: 200px;
				}
			}
		}
	}
}

@media screen and (max-width: (780px)) {
	.tires-wrapper {
		&__group {
			&-set {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}
}

@media screen and (max-width: (600px)) {
	.tires-wrapper {
		&__group {
			&-set {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}

@media screen and (max-width: (450px)) {
	.tires-wrapper {
		&__group {
			&-set {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}
