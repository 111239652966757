.FilterButton {
	display: none;
	align-items: center;
	background-color: #fff;
	border: 2px solid #dee6f5;
	border-radius: 6px;
	font-size: 14px;
	font-weight: 700;
	gap: 4px;
	justify-content: center;
	line-height: 17px;
	padding: 6px 0;
	position: relative;
	width: 112px;

	&.indent {
		margin-top: 16px;
	}

	&.indentBottom {
		margin-bottom: 16px;
	}

	@media (max-width: 780px) {
		display: flex;
	}
}
