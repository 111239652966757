.single-auction-wrapper,
.edit-auction-wrapper {
	padding: 20px 30px 0 29px;

	.auction-info {
		padding: 15px 30px;
		background: #ffffff;
		border: 1px solid var(--main-color-light-blue);
		box-sizing: border-box;
		border-radius: 8px;

		&__field-wrapper {
			border-bottom: 1px solid #dee6f5;
			padding: 10px 0;
		}

		&__field-wrapper:last-child {
			border-bottom: none;
		}
	}

	.auction-name {
		font-weight: 700;
		font-size: 22px;
		line-height: 135%;
		font-feature-settings: "pnum" on, "lnum" on;
		color: #283445;
	}

	.status {
		display: flex;
		gap: 8px;
		align-items: center;
		padding: 5px 12px 6px;
		border-radius: 6px;
		font-weight: 700;
		font-size: 12px;
		box-sizing: border-box;
		border: none;

		svg {
			border-left: 1px solid #2834451f;
			padding: 0 0 0 4px;
		}
	}

	.green {
		background: #6ac96e1a;
		color: #339337;

		svg {
			fill: #339337;
		}
	}

	.orange {
		background: rgba(254, 179, 92, 0.1);
		color: #f17b00;

		svg {
			fill: #f17b00;
		}
	}

	.red {
		background: rgba(240, 68, 97, 0.1);
		color: #f04461;

		svg {
			fill: #f04461;
		}
	}

	.row-container {
		border-bottom: 1px solid var(--main-color-light-blue);
		padding: 10px 0;

		&:last-child {
			border-bottom: 0;
		}
	}

	.row-name {
		width: 200px;
		font-weight: 600;
		color: #283445;
	}

	.reports-btn {
		border: 1px solid #c3c3c7;
		margin-left: 10px;
		background: #f4f6fb;
		width: 100%;
		max-width: 293px;
		padding: 11px 28px;
		margin-right: 15px;
		border-radius: 8px;
		font-weight: bold;
		font-size: 14px;
		text-align: center;
		outline: none;
		color: black;
	}

	.info-wrapper {
		padding: 30px;
		background: #ffffff;
		border: 1px solid var(--main-color-light-blue);
		box-sizing: border-box;
		border-radius: 8px;
	}

	.name {
		font-weight: bold;
		font-size: 16px;
		line-height: 140%;
		font-feature-settings: "pnum" on, "lnum" on;
		color: #283445;
		display: flex;
		align-items: center;
	}

	.image {
		width: 80px;
		height: 80px;
		object-fit: cover;
		border-radius: 50%;
	}

	.table-bordered > :not(caption) > * > * {
		overflow: hidden;
	}

	.link {
		color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
		display: inline-block;

		&:hover {
			color: var(--bs-link-hover-color);
		}
	}

	.table {
		.btn {
			min-width: 136px;
		}

		&__file-blue-text {
			font-weight: 700;
			font-size: 14px;
			line-height: 20px;
			color: #2d78e9;
			margin-right: 5px;
			text-decoration: underline !important;
		}

		&__trades-history {
			font-weight: 700;
			font-size: 14px;
			display: flex;
			align-items: center;
			gap: 4px;
			line-height: 20px;
			color: #2d78e9;
			cursor: pointer;
		}

		.edit-icon {
			padding: 4px;
			border-radius: 50%;
			cursor: pointer;
		}

		.edit-icon:hover {
			background-color: #3c83ed1a;
		}
	}
}

.buy-page-wrapper {
	padding: 20px 0;
}
