.car-checks {
	display: flex;
	align-items: center;
	border-top: 1px solid #dee6f5;
	margin-top: 16px;
	padding-top: 16px;
	gap: 10px;

	&__automart {
		display: flex;
		gap: 4px;
		font-size: 14px;
		align-items: center;
		padding: 6px 10px;
		border-radius: 6px;
		line-height: 16px;
	}

	&__automart_seller {
		background-color: #edf1fa;
		color: #656c7b;
	}

	&__automart_automart {
		background-color: rgba(60, 131, 237, 0.1);
		color: #2d78e9;
	}

	&__check-icons {
		margin-left: 30px;
		display: flex;
		gap: 10px;
		align-items: center;
	}

	&__shield {
		display: inline-flex;
	}

	&__btn {
		display: flex;
		gap: 8px;
		background-color: #f4f6fb;
		border-radius: 8px;
		justify-content: center;
		align-items: center;
		padding: 8px 10px;
		color: #2d78e9;
		font-weight: 700;
	}

	&__comment {
		color: #9ca3b0;

		&-has {
			color: #2d78e9;
		}
	}
}

@media screen and (max-width: 780px) {
	.car-checks {
		flex-direction: column;
		margin-top: 12px;
		padding-top: 12px;
		width: 100%;

		&__btn {
			width: 100%;
			padding: 12px;
		}

		&__automart,
		&__check-icons {
			gap: inherit;
		}

		&__check-icons {
			margin-left: 0 !important;
		}
	}
}

.terms-of-cooperation-modal-wrapper .modal-content {
	width: 540px;
	display: flex;
	gap: 20px;
	padding: 40px;
}

.terms-of-cooperation-modal-wrapper .modal-dialog {
	display: flex;
	justify-content: center;
}
