.input-wrapper {
	position: relative;

	// &.datetime-local:after {
	// 	background-image: url(../../../public/img/icons/calendar-icon.svg);
	// 	width: 16px;
	// 	height: 16px;
	// 	content: "";
	// 	pointer-events: none;
	// 	position: absolute;
	// 	right: 19px;
	// 	top: 50%;
	// 	translate: 0 -50%;
	// }
}

.input {
	padding: 0 16px;
	color: #283445;
	width: 100%;
	font-size: 16px;
	line-height: 18px;
	background: #f4f6fb;
	border: 1px solid rgba(156, 163, 176, 0.1);
	box-sizing: border-box;
	border-radius: 6px;
	outline: none;
	font-weight: 500;

	&_l {
		height: 44px;
	}

	&_m {
		height: 40px;
	}

	&_s {
		height: 36px;
	}

	&_xs {
		height: 32px;
		font-size: 14px;
	}

	&:disabled {
		opacity: 0.4;
		cursor: default;
		pointer-events: none;
	}

	&::placeholder,
	&::-webkit-datetime-edit {
		color: #bbbbbb;
	}

	&_white {
		background-color: #fff;
	}

	// &::-webkit-calendar-picker-indicator {
	// 	color: transparent;
	// 	background: none;
	// 	z-index: 1;
	// }
}

.input:hover {
	border: 1px solid #8a92a1;
}
