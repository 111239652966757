.Icon16 {
	width: 16px;
	height: 16px;
}

.Icon24 {
	width: 24px;
	height: 24px;
}

.Icon30 {
	width: 30px;
	height: 30px;
}

.Icon40 {
	width: 40px;
	height: 40px;
}
