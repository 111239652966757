.ModalInfo {

	:global(.modal-dialog) {

		:global(.modal-content) {
			max-width: 320px;
			margin: 0 auto;
		}
	}

	.section {

	}

	.title {
		font-weight: bold;
		text-align: center;
		margin-bottom: 16px;
	}

	.footer {
		display: flex;
		justify-content: center;
	}

	.reload {
		color: #3c83ed;
		display: flex;
		justify-content: center;
		margin-bottom: 8px;
	}
}
