.Checkbox {
	display: flex;
	position: relative;
	cursor: pointer;
	align-items: center;

	input {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}

	&.label {
		span {
			margin-right: 8px;
		}
	}

	&.labelBefore {
		span {
			margin-left: 8px;
		}
	}

	&.radio {
		span {
			border-radius: 50%;

			&:after {
				border-radius: 50%;
			}
		}
	}

	span {
		flex-shrink: 0;
		// translate: 0 2px;
		border: 2px solid #9ca3b0;
		border-radius: 6px;
		width: 20px;
		height: 20px;
		position: relative;

		&:after {
			width: 12px;
			height: 12px;
			background: #2d78e9;
			border-radius: 4px;
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			content: "";
			opacity: 0;
			scale: 0;
			transition: 0.3ms ease-in-out;
		}
	}

	input:checked + span {
		&:after {
			opacity: 1;
			scale: 1;
		}
	}
}
