.ToogleApproveVins {
	border: 1px solid #dee2e6;
	border-radius: 8px;
	background: #fff;
	padding: 16px 16px 24px;

	.title {
		margin-bottom: 8px;

		code {
			color: #f70000;
			background: #f4f6fb;
			border-radius: 4px;
			padding: 0 4px;
		}
	}
	
	.textarea {
		margin-bottom: 16px;
	}

	.buttons {
		display: flex;
		gap: 16px
	}
}

.buttonsModal {
	display: flex;
	justify-content: center;
	gap: 16px;
	padding-top: 16px;
}