.company-wrapper {
	padding: 40px 30px 0 29px;

	&-profile {
		padding: 40px 0 0
	}

	.title {
		font-weight: 700;
		font-size: 22px;
		line-height: 135%;
		font-feature-settings: "pnum" on, "lnum" on;
		color: #283445;
	}

	.company-info {
		padding: 15px 30px;
		background: #ffffff;
		border: 1px solid #dee6f5;
		box-sizing: border-box;
		border-radius: 8px;

		&__field-wrapper {
			border-bottom: 1px solid #dee6f5;
			padding: 10px 0;
		}

		&__field-wrapper:last-child {
			border-bottom: none;
		}
	}

	.name {
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		font-feature-settings: "pnum" on, "lnum" on;
		color: #283445;
		display: flex;
		align-items: center;
	}

	.value {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		font-feature-settings: "pnum" on, "lnum" on;
		color: #283445;
	}

	.image {
		width: 80px;
		height: 80px;
		object-fit: cover;
		border-radius: 50%;
	}

	@media screen and (max-width: 560px) {
		.company-info {
			padding: 15px;
			
			&__field-wrapper {
				display: flex !important;
				flex-direction: column;
			}
		}
	}
}
