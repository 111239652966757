.buy-wrapper {
	.last-bet-is-my {
		display: inline-flex;
		padding: 8px 12px;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		border-radius: 6px;
		background: rgba(106, 201, 110, 0.1);
		color: #339337;
		align-items: center;
		justify-content: center;
	}

	.last-bet-is-not-my {
		display: inline-flex;
		padding: 8px 12px;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		border-radius: 6px;
		background: rgba(240, 68, 97, 0.1);
		color: #f04461;
		align-items: center;
		justify-content: center;
	}

	td {
		padding: 6px !important;
	}

	.table__file-extra-text {
		color: #656c7b;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
	}

	.table__file-blue-text {
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		color: #2d78e9;
		text-decoration: underline !important;
	}

	.filter {
		& > div {
			gap: 10px;
		}
	}

	.min-height {
		min-height: 80px;

		&:not(:first-child) {
			position: relative;
			padding-top: 8px;

			&:before {
				content: "";
				position: absolute;
				left: -6px;
				right: -6px;
				top: 0;
				border-top: 1px dashed var(--bs-border-color);
			}
		}
	}
}
