.BtnDelete {
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;

	svg {
		margin-right: 8px;
		color: #fff;
	}
}
