.RolesList {
	padding: 40px 0 0;

	.header {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
