.Input {
	&::placeholder,
	&::-webkit-datetime-edit {
		color: #bbbbbb;
	}

	&[type="date"] {
		position: relative;
		padding: 0 8px 0 16px;

		//&::-webkit-calendar-picker-indicator {
		//	background: url(./Input.date.svg) center center no-repeat;
		//}
	}
}
