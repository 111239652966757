.PhotoReport {
	position: relative;

	:global(.img-upload) {
		position: relative;
	}
	
	:global(.file-input) {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 1;
	}
}
