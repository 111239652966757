.Combox {
	position: relative;

	select {
		width: 100%;
		appearance: none;
		height: 41px;
		display: block;
		border: 1px solid rgba(156, 163, 176, 0.1);
		border-radius: 6px;
		background: rgb(244, 246, 251);
		outline: none;
		padding: 0 20px;

		option {
			padding: 4px 20px;
		}
	}
}
