.ReportList {

	.main {
		margin-bottom: 16px;
	}

	:global(.selection-cell) {
		input {
			margin: 0;
		}
	}
}
