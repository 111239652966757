html {
	--scrollbarBG: #EDF1FA;
	--thumbBG: #2D78E9;
}

::-webkit-scrollbar {
	width: 11px;
}

* {
	scrollbar-width: thin;
	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

::-webkit-scrollbar-track {
	background: var(--scrollbarBG);
}

::-webkit-scrollbar-thumb {
	background-color: var(--thumbBG) ;
	border-radius: 6px;
	border: 3px solid var(--scrollbarBG);
}

.wrapper {
	width: calc(100% - var(--vars-left-menu-width));
	margin-left: var(--vars-left-menu-width);
}

@media screen and (max-width: 780px) {
	.wrapper,
	.wrapper.wrapper-full {
		width: 100vw !important;
	}

	.wrapper {
		margin-left: 0;

		.children {
			transition: filter 0.4s ease-in-out;
			filter: brightness(0.7) blur(1px);
			pointer-events: none;
		}

		&.wrapper-full .children {
			filter: unset;
			pointer-events: unset;
		}

		&.wrapper-full .header-menu .d-flex .user {
			display: none !important;
		}
	}
}
