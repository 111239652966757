.Container {
	padding: 20px 30px;
	border-radius: 8px;
	border: 1px solid var(--Light-Border, #DEE6F5);
	background: #FFF;
	margin-bottom: 35px;

	@media screen and (max-width: 560px) {
		padding: 10px 16px;
	}
}
