.auction-page-wrapper {
	padding: 20px 30px;

	.arrow-down {
		margin-left: 20px;
	}

	&__header {

	}

	.filters-wrapper {
		margin: 25px 0 15px;

		.filter {
			padding: 20px;
			margin-top: 15px;
			background: white;
			border: 1px solid #DEE6F5;
			box-sizing: border-box;
			border-radius: 8px;

			& > div {
				gap: 10px;
			}

			.input, .react-select {
				width: 265px;
			}
		}
	}

	@media (max-width: 780px) {
		&__header {
			justify-content: space-between !important;
			align-items: center !important;
		}
	}
}
