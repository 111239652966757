.info-list {

	&__title {
		font-weight: 700;
		font-size: 22px;
		line-height: 135%;
		color: #283445;
	}

	&__content {
		padding: 15px 30px;
		background: #FFFFFF;
		border: 1px solid var(--main-color-light-blue);
		box-sizing: border-box;
		border-radius: 8px;
	}

	&__item {
		display: grid;
		grid-template-columns: 30% 70%;
		border-bottom: 1px solid #DEE6F5;
		padding: 10px 0;
		grid-gap: 10px;

		&:last-child {
			border-bottom: none;
		}
	}

	&__item-value {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: #283445;
	}

	&__item-label {
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		color: #283445;
	}
}

@media screen and (max-width: 780px) {
	.info-list {

		&__item {
			word-break: break-word;
			grid-template-columns: 45% 55%;
		}

		&__content {
			padding: 15px;
		}
	}
}

@media screen and (max-width: 560px) {

	.info-list {
		&__item {
			display: flex;
			flex-direction: column;
			align-items: flex-start !important;
		}
	}
}
