.single-user-page-wrapper {
	padding: 20px 30px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		font-weight: 700;
		font-size: 22px;
		line-height: 135%;
		color: #283445;
	}

	.checkbox {
		opacity: 0.7;
	}

	.user-info {
		padding: 15px 30px;
		background: #FFFFFF;
		border: 1px solid var(--main-color-light-blue);
		box-sizing: border-box;
		border-radius: 8px;

		&__field-wrapper {
			display: grid;
			grid-template-columns: 30% 70%;
			border-bottom: 1px solid #DEE6F5;
			padding: 10px 0;
			grid-gap: 10px;
		}

		&__field-wrapper:last-child {
			border-bottom: none;
		}
	}

	.name {
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		color: #283445;
	}

	.value {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: #283445;
	}

	.user__companies span {
		padding-right: 5px;
	}

	.modal-wrapper {
		padding: 25px;
	}

	.modal-content {
		width: 38%;
		margin: 0 auto;
	}

	.content-modal {
		padding: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.block-btn {
			border: 1px solid #c3c3c7;
			margin-left: 10px;
			background: #F4F6FB;
			width: 100%;
			max-width: 202px;
			padding: 11px 28px;
			margin-right: 15px;
			border-radius: 8px;
			font-weight: bold;
			font-size: 14px;
			text-align: center;
			outline: none;
		}

		.head-text {
			font-weight: 700;
			font-size: 22px;
			line-height: 135%;
			font-feature-settings: 'pnum' on, 'lnum' on;
			color: #283445;
		}
	}

	.user-info__checkboxs {
		height: 40px;
		display: flex;
		align-items: center;
	}

	.user-info__checkbox {
		opacity: 0.7;
		margin-right: 50px;
		display: flex;
		align-items: center;
		line-height: 18px;

		.form-check-label {
			line-height: 18px;
		}
	}
}

@media screen and (max-width: 780px) {
	.single-user-page-wrapper {
		padding: 20px;

		.user-info {
			padding: 15px;
		}

		.user-info__field-wrapper {
			word-break: break-word;
			grid-template-columns: 45% 55%;
		}

		.user-info__checkboxs {
			flex-direction: column;
			align-items: start;
			gap: 4px;
		}

		.user-info__checkbox {
			margin-right: 20px;

			input {
				display: none;
			}

			.form-check-label {
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 560px) {
	.single-user-page-wrapper {

		.user-info {
		}

		.user-info__field-wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start !important;
		}

		.user-info__checkboxs {
		}

		.user-info__checkbox {

			.form-check-label {
			}
		}
	}
}

