.total-wrapper {
  padding: 20px 30px;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;

  .title {
    font-weight: bold;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
  }

  .arrow-down {
    margin-left: 20px;
    cursor: pointer;
  }

  .btn-filters {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 2px solid #DEE6F5;
    border-radius: 6px;
    padding: 6px 0;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    width: 112px;
  }

  .filters-wrapper {

    .input, .react-select {
      max-width: 265px;
    }
  }

  .statistics-wrapper {
    margin: 25px 0;

    .little-text {
      margin-top: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #9CA3B0;
    }
  }

  .auctions-wrapper {
    .action {
      margin-right: 43px;
      cursor: pointer;

      .type {
        margin: 0 13px;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #283445;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .total-wrapper {
    padding: 24px 16px;

    .header-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.filters-wrapper {
  margin: 25px 0 15px 0;

  .filter {
    padding: 20px;
    margin-top: 15px;
    background: white;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;
  }
}
