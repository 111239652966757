.users-page-wrapper {
	padding: 20px;

	.tabs {
		.tab-btn {
			margin-left: 10px;
			outline: none;
			padding: 10px 30px;
			border-radius: 60px;
			font-weight: bold;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			font-feature-settings:
				"pnum" on,
				"lnum" on;
			color: #283445;
			background: white;
			border: 1px solid #dee6f5;
		}

		.tab-btn-active {
			border: none;
			background: #283445;
			color: white;
		}
	}

	.role-wrapper {
		display: flex;
		flex-direction: column;
		gap: 2px;
		align-items: flex-start;
	}

	.role {
		padding: 0 8px;
		border-radius: 6px;
		background: #edf1fa;
		color: #656c7b;
		font-size: 12px;
		line-height: 1;
		font-weight: 700;
		height: 28px;
		display: flex;
		align-items: center;
	}

	.green {
		color: #46ad4a;
	}

	.users-company-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 200px;
		display: block;
	}
}
