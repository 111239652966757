.AddRole {
	padding: 40px 30px;

	.footer {
		display: flex;
		justify-content: space-between;
	}

	.submit {
		margin-right: 8px;
	}
}
