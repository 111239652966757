.companies-wrapper {

	.filters-wrapper {
		margin: 25px 0 15px 0;

		.filter {
			padding: 20px;
			margin-top: 15px;
			background: white;
			border: 1px solid #DEE6F5;
			box-sizing: border-box;
			border-radius: 8px;

			& > div {
				gap: 10px;
			}

			.input, .react-select {
				width: 265px;
			}
		}
	}

	.input, .react-select {
		max-width: 265px;
	}

	.arrow-down {
		margin-left: 20px;
	}

	.name {
		font-weight: 700;
		font-size: 22px;
		line-height: 135%;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: #283445;
	}

	.role-wrapper {
		display: flex;
		flex-direction: column;
		gap: 2px;
		align-items: flex-start;
	}

	.role {
		padding: 0 8px;
		border-radius: 6px;
		background: #edf1fa;
		color: #656c7b;
		font-size: 12px;
		line-height: 1;
		font-weight: 700;
		height: 28px;
		display: flex;
		align-items: center;
	}

	@media (max-width: 780px) {
		.input, .react-select {
			max-width: none;
		}
	}
}

