.table-class {
	margin-bottom: 0;

	tbody tr:last-child {
		border-bottom: hidden;
	}
}

.table-class-wrap {
	overflow-x: auto;
	border-radius: 8px;

	.table {
		min-width: 1150px;

		tr:last-child th:first-child {
			border-radius: 8px 0 0 0;
		}

		tr:last-child th:last-child {
			border-radius: 0 8px 0 0;
		}

		tr:last-child td:first-child {
			border-radius: 0 0 0 8px;
		}

		tr:last-child td:last-child {
			border-radius: 0 0 8px 0;
		}
	}
}

.react-bootstrap-table {
	.table td {
		word-break: break-word;
	}

	td,
	td div,
	td span,
	th {
		font-weight: 500;
		font-size: 14px;
		color: #283445;
		line-height: 18px;
	}

	td a {
		font-weight: 500;
	}

	thead tr th {
		font-weight: 700;
		vertical-align: top;
	}
}

.table {
	.border {
		border: 1px solid #dee6f5;
		border-bottom: 0;
	}

	td {
		background-color: #fff;
	}

	thead {
		background: #e9effa;
	}

	& > :not(:first-child) {
		border-top: unset !important;
	}

	.order-4,
	.caret-4-asc,
	.caret-4-desc {
		float: right;
	}
}

.react-bootstrap-table table.table-not-fixed {
	table-layout: auto;
}

.w-300 {
	min-width: 300px;
}

.w-250 {
	min-width: 250px;
}

.w-200 {
	min-width: 200px;
}

.w-150 {
	min-width: 150px;
}

.w-100 {
	min-width: 100px;
}

@media screen and (max-width: 780px) {
	.table-class-wrap {
		.table {
			min-width: 800px;
		}
	}
}
