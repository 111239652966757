.RoleViewAdmin {
	padding: 40px 0;

	.header {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.btn {
		display: flex;
		grid-gap: 8px;
	}

	@media screen and (max-width: 560px) {

		.header {
			flex-direction: column;
			align-items: flex-start;
		}

		.btn {
			padding-top: 8px;
		}
	}
}
