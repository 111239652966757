.BtnText {
	border: none;
	padding: 0;
	margin: 0;
	background: none;
	color: var(--bs-blue);
	display: flex;
	align-items: center;
	transition: color 0.3s ease-in-out;

	&:hover {
		color: rgba(10, 88, 202, 1);
	}

	svg {
		margin-right: 8px;
	}

	span + svg {
		margin-right: 0;
		margin-left: 8px;
	}
}
