.LogoSeller {
	height: 48px;
	max-width: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;

	img {
		max-width: 100%;
		max-height: 100%;
	}

	&.white {
		background-color: #fff;
		padding: 4px;
	}

	&.small {
		height: 24px;
		max-width: 60px;
	}
}
