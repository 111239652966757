.UploadDocument {
	.section {
		padding: 20px 30px 30px;
		background: #ffffff;
		border: 1px solid #dee6f5;
		box-sizing: border-box;
		border-radius: 8px;
	}

	&.isPopup .section {
		padding: 16px;
	}

	.footerText {
		font-size: 12px;
		color: #bbbbbb;
		text-align: center;
		padding-top: 8px;
		margin-bottom: -8px;
	}

	@media (max-width: 780px) {
		.section {
			padding: 20px 15px;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
	}
}
